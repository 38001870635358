import { colors } from './colors'
import { fonts } from './fonts'
import { text } from './text'
import { forms } from './forms'
import { buttons } from './buttons'
import { Theme } from 'theme-ui'

const theme: Theme = {
  initialColorModeName: 'dark',
  buttons,
  colors,
  fonts,
  forms,
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [10, 12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  text,
  layout: {
    container: {
      paddingX: 4,
    },
  },
  links: {
    navigation: {
      color: 'inherit',
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      '&:hover,&.active': {
        color: 'primary',
      },
    },
    button: {
      bg: 'primary',
      display: 'inline-block',
      textDecoration: 'none',
      color: 'white',
      borderRadius: 0,
      fontFamily: 'body',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      my: 2,
      py: 2,
      px: 4,
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    strong: {
      color: 'primary',
    },
    a: {
      color: 'primary',
      textDecoration: 'none',
    },
    h1: {
      fontSize: 3,
      fontFamily: 'heading',
      fontWeight: 'heading',
      color: 'primary',
      mt: 4,
      mb: 2,
    },
    h2: {
      fontSize: 2,
    },
    h3: {
      fontSize: 2,
    },
    p: {
      marginY: 4,
    },
    hr: {
      borderBottom: '1px solid',
      borderColor: 'neutral.800',
    },
  },
  sizes: {
    container: 1280,
  },
  badges: {
    primary: {
      bg: 'secondary',
      px: 2,
      py: 1,
      fontWeight: 'bold',
      fontSize: 1,
      letterSpacing: 1,
    },
  },
}

export default theme
